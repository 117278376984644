import React from "react"
import Answer from "./Answer"
import twitter from "../images/twitter-icon.png"

function Question(props) {
    const { id, question, correct, correctId, answers, answerIds, selected, holdAnswer, check } = props

    const answerElements = answers.map((answer, i) => (
        <Answer
            key={id + i}
            correct={correct}
            correctId={correctId}
            answer={answer}
            answerId={answerIds[i]}
            selected={selected}
            holdAnswer={holdAnswer}
            check={check}/>
    )) //console.log('answerElements: ', answerElements)
    
    const tweetString = question + '%0a%0a' + 
                    'A. ' + answers[0] + '%0a' +
                    'B. ' + answers[1] + '%0a' +
                    'C. ' + answers[2] + '%0a' + 
                    'D. ' + answers[3] + '%0a' +
                    '%0aDiscover more at www.bitcointrivia.app'

    return (
        <div className="quiz--card">
            <div className="quiz--tweet">
                <section className="quiz--question"
                         aria-label={question}>
                         {question}
                </section>
                <div className="quiz--twitter">
                    <a href={'https://twitter.com/intent/tweet?text=%23Bitcoin Trivia Time%0a%0a' + tweetString + "%0a%0a%23bitcointrivia"}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Tweet Question">
                    <img className="quiz--twitter__icon"
                        src={twitter}
                        alt="Titter icon to  tweet questions and answers."
                        aria-label="Twitter icon"/>
                    </a>
                </div>
            </div>
            <div className="quiz--answers">{answerElements}</div>
        </div>
    )
}

export default Question